export const Logger = Object.assign({}, console, {
  logs: <string[]>[],

  dump() {
    return this.logs.join('\n')
  },

  tableCollapsed(this: Console, title: string, content: any) {
    this.groupCollapsed(title)
    this.table(content)
    this.groupEnd()
  },

  groupError(this: Console, context: string, error: unknown) {
    this.groupCollapsed(`%c⚠️ ${context}`, 'color: red')
    this.error(error)
    this.groupEnd()
  },

  use(prefix: string) {
    return Object.keys(console).reduce((record, _key) => {
      const key = _key as keyof Console
      const fn = record[key]

      if (typeof fn === 'function')
        // @ts-expect-error type issue
        record[key] = fn.bind(null, prefix)

      return record
    }, Object.assign({}, console))
  }
})

// ! Override console in prod to dump logs
if (import.meta.env.PROD) {
  const ref = globalThis.console
  const keys = ['log', 'warn', 'error', 'info'] as const

  keys.forEach((key) => {
    const fn = ref[key]

    console[key] = (...args: any[]) => {
      try {
        fn(...args)
        Logger.logs.push(`[${key.toUpperCase()}] ${args.join(' ')}`)
      }
      catch (error) {
        console.error('Error in logger middleware\n', error)
      }
    }
  })
}

export function useLogger(title: string) {
  return console.debug.bind(console, title)
}

/** A logger class decorator */
export function _log(target: any, key: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value

  descriptor.value = function (...args: any[]) {
    const result = originalMethod.apply(this, args)
    console.debug(`${target.constructor.name}: ${key}(${args.join(', ')}) => ${result}`)
    return result
  }

  return descriptor
}
