import { DeferredPromise, mount } from 'core'
import PopupView from '../../common/PopupView.vue'
import App from '../components/Payment.vue'
import { useEvents } from './config'
import { useState } from './state'

const DefaultPaywallProps = {
  noHeader: false,

  /** Whether paywall can be closed */
  canSkip: true,

  external: false
}

export const usePaywall = createGlobalState(() => {
  /** Whether popup is active */
  const isActive = ref(false)

  /** Props for paywall component */
  const props = shallowReactive(DefaultPaywallProps)

  const state = useState()
  const events = useEvents()
  const session = useSession()

  function show(isOpen = true) {
    if (session.settings.useExternalPaywall)
      return session.openPaymentFlow()

    isActive.value = isOpen
    events.popupChange.trigger(isOpen)

    track(isOpen ? 'Paywall Opened' : 'Paywall Closed')

    if (isOpen)
      return waitForClose()

    // Reset props
    else Object.assign(props, DefaultPaywallProps)
  }

  const hide = () => show(false)
  const toggle = () => show(!isActive.value)

  function ViewApp() {
    function onChange(isOpen: boolean) {
      if (!isOpen && !props.canSkip)
        return

      show(isOpen)
    }

    return (
      <PopupView
        modelValue={isActive.value}
        onUpdate:model-value={onChange}
        animate-from="bottom"
        style={{ scrollSnapType: 'y mandatory' }}
        class="flex sm:p-10 bg-utility-overlay"
      >
        <App class="relative m-auto <md:mb-0 max-h-100vh overflow-y-auto stable-scrollbar" {...props} onClose={props.canSkip ? hide : undefined} />
      </PopupView>
    )
  }

  function setProps(params: Partial<typeof DefaultPaywallProps>) {
    return Object.assign(props, params)
  }

  /** Waits until popup is closed */
  async function waitForClose() {
    const promise = new DeferredPromise<void>()

    const { off } = events.popupChange.on((isOpen) => {
      if (isOpen)
        return

      off()
      promise.resolve()
    })

    return promise
  }

  // Events
  events.finish.on(hide)

  // Global mount
  const view = mount(ViewApp, { element: document.body })

  return { isActive, view, state, events, show, hide, toggle, props, setProps }
})

export function usePaywallStats() {
  const user = useUserAccount()

  return computedWithControl(
    () => user.id,
    () => {
      const { count, max } = user.messages

      const progress = Math.min((count / max) * 100, 100)

      return {
        current: count,
        max,
        progress,

        /** Whether max limit is reached */
        maxed: count >= max
      }
    }
  )
}
