import payload_plugin_BIrecQ5YON from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_TAflVmDPTf from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6Edtyz59DC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_L2krD7nljS from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_YcRGMg4vOy from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4._kkguupk6xtxueyvpzciwxfl3i4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_vtEjpwWfLy from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ejBDLdz82A from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZcKqROqXAt from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_XrEsbQIsmL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_fLjJoCtWeE from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_7Fivwtyrmh from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_xjkcT3exTi from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@_goxnhmqaq6fccdcck6lv2cvvgi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_XFrwWa5apm from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7_idpdqf7g3vwkbdfpb24mleetnm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import titles_2OwzhCIpDL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_@unhead+vue@1.11.15_vue@3.5.13_typescript@5.7.3___h3@1.13.1_magicast@_d3p72z3hrhlm6idisx47qpfl4m/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_FrRiFQuqGr from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2_fyyqpqluzc6hg6af6twespdwre/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_7PHPJbCCGG from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2_fyyqpqluzc6hg6af6twespdwre/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
import defaults_90JSOZJWf3 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_@unhead+vue@1.11.15_vue@3.5.13_typescript@5.7.3___h3@1.13.1_magicast@_d3p72z3hrhlm6idisx47qpfl4m/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_BIrecQ5YON,
  revive_payload_client_TAflVmDPTf,
  unhead_6Edtyz59DC,
  router_L2krD7nljS,
  _0_siteConfig_YcRGMg4vOy,
  payload_client_vtEjpwWfLy,
  navigation_repaint_client_ejBDLdz82A,
  check_outdated_build_client_ZcKqROqXAt,
  view_transitions_client_XrEsbQIsmL,
  chunk_reload_client_fLjJoCtWeE,
  plugin_vue3_7Fivwtyrmh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xjkcT3exTi,
  unocss_MzCDxu9LMj,
  plugin_XFrwWa5apm,
  titles_2OwzhCIpDL,
  siteConfig_FrRiFQuqGr,
  inferSeoMetaPlugin_7PHPJbCCGG,
  _00_setup_OesUu83x3t,
  defaults_90JSOZJWf3
]