import type { RemovableRef, StorageLikeAsync } from '@vueuse/core'
import { createErrorMap, type InferErrorFromMap } from 'core'
import idb from 'localforage'
import { AudioBlob } from 'vocal-recorder'

/** IDB key of vocal recording result */
export const AudioResultIDBKey = 'vocal-audio-result'

export const log = Object.assign(
  console.debug.bind(null, '%c🤖 RecorderUI:', 'color: bluesky'),
  {
    error: console.error.bind(null, '%c🤖 RecorderUI:', 'color: bluesky')
  }
)

/** Possible error for recorder ui */
export const Errors = createErrorMap({
  // Asset related
  SignatureRenderFailed: 'Failed to render the signature image',

  // Server related
  UIDFetchFailed: 'Failed to get a new uid token from server',
  UIDAlreadyUsed: 'An audio has been already uploaded using this uid',
  NetworkIssue: 'We had an unexpected issue while connecting with the server',

  // Flow related
  InitFailed: 'Failed to initialize the recorder',
  UploadFailed: 'Upload process failed',
  NullRecordingResult: 'Recording produces no result',

  // Microphone releated
  MicPermissionDenied: 'Microphone permission has been denied',
  MicNotFound: 'No microphone devices were found'
})

/** Represents Error type from - {@link Errors} map */
export type RecorderError = InferErrorFromMap<typeof Errors>

export const useAudioDropzone = createSharedComposable(() => {
  /** Max size of allowed audio file - 500mb */
  const maxSize = 5e+8

  const audio = ref<AudioBlob>()
  const isProcessing = ref(false)
  const isReady = ref(false)

  // Events
  const { on: onGetAudio, trigger: setAudioFile } = createEventHook<AudioBlob>()

  const { isOverDropZone } = useDropZone(document.body, {
    dataTypes(types) {
      const allowed = [
        'audio/wav',
        'audio/mpeg',
        'audio/m4a'
      ]

      return !!types.find(i => allowed.includes(i))
    },

    onDrop: onFilesDropped
  })

  // TODO: add fix in vocal-recorder upstream
  /** Fix issues with dropped audio file mimetypes */
  function cleanupFile(file: File) {
    switch (file.type) {
      case 'audio/x-m4a':
        return new File([file], file.name, { type: 'audio/mp4' })

      default:
        return file
    }
  }

  async function onFilesDropped(files: File[] | null) {
    if (!files || files.length <= 0)
      return

    const file = cleanupFile(files[0])

    if (file.size > maxSize)
      return alert('Audio file size is larger than 50MB. Large files are not supported.')

    isProcessing.value = true
    audio.value = await AudioBlob.parse(file)
    await setAudioFile(audio.value)
    isProcessing.value = false

    track('Dropped audio file', {
      name: file.name,
      duration: audio.value.duration.seconds
    })

    isReady.value = true
  }

  // Opens files window to select an audio file
  async function selectFile() {
    const audio = await chooseFile({
      accept: 'audio/*'
    })

    if (audio)
      await onFilesDropped([audio])
  }

  function clear() {
    delete audio.value
    isProcessing.value = false
    isReady.value = false
  }

  return { maxSize, audio, isOverDropZone, isProcessing, isReady, clear, onGetAudio, selectFile }
})

/** Stores {@link AudioBlob} in IndexedDB storage */
export function usePersistentAudio(key: string, initialValue?: AudioBlob) {
  return useStorageAsync(key, initialValue as any, idb as StorageLikeAsync, {
    shallow: true,

    serializer: {
      read(file: any) {
        if (file instanceof Blob === false)
          throw new Error(`Blob was stored as type: ${typeof file}`)

        return AudioBlob.parse(file)
      },

      write: blob => blob
    }
  }) as unknown as RemovableRef<AudioBlob | undefined>
}
