import type { StripeCardElementOptions, StripeElementsOptionsClientSecret } from '@stripe/stripe-js'
import type { Undef } from 'core'
import type { MaybeRef } from 'vue'
import { StripeElement } from './stripe'

namespace Config {
  export const Elements: StripeElementsOptionsClientSecret = {
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Inter' }],

    appearance: {
      theme: 'flat'
    }
  }

  export function CardStyles(classes: Record<string, string>): StripeCardElementOptions {
    return {
      style: {
        base: {
          'backgroundColor': 'transparent',

          // Typography
          'color': 'rgb(77, 107, 156)', // text-neutral-600
          'iconColor': 'rgb(19, 39, 71)', // text-neutral-900

          'fontFamily': 'Inter',
          'fontSize': '12px',
          'fontWeight': 400,

          '::placeholder': {
            color: '#8BA6C7', // text-neutral-400
            fontSize: '12px',
            fontWeight: 500
          }
        }
      },

      classes: {
        empty: classes.empty,
        focus: classes.focus,
        invalid: classes.invalid,
        complete: classes.complete
      }
    }
  }
}

export class ReactiveStripeElement extends StripeElement {
  styles = useCssModule()
  state = shallowReactive({
    isValid: false,
    isLoaded: false,

    error: '' as Undef<string>,

    /** Stripe 3DS authentication page url */
    checkoutUrl: '' as Undef<string>
  })

  async #mountCardForm(wrapper: MaybeRef<HTMLElement | undefined>) {
    await this.instance

    const state = this.state
    const card = await super.mountCardForm(wrapper, Config.Elements, Config.CardStyles(this.styles))

    // Listen to card events
    card.once('ready', () => state.isLoaded = true)
    card.on('change', (event) => {
      state.isValid = event.complete && !event.error
      state.error = event.error?.message
    })

    return card
  }

  InputCard = defineComponent(() => {
    const wrapper = ref<HTMLElement>()
    onMounted(() => this.#mountCardForm(wrapper))

    return () => <div ref={wrapper} data-loaded={this.state.isLoaded} />
  })
}
