import type { Recording } from './models'
import { track } from '../../../diagnostics'
import { downloadFile } from '../../../utils/file'
import { copyRichHTML, copyText, parseURL } from '../../../utils/helpers'

/** Converts WebVTT to text lines */
export function parseTranscriptionContent(input?: Recording.ITranscription | null) {
  if (!Array.isArray(input))
    return { lines: [], text: '' }

  const lines = input.flatMap(i => i.sentences).flatMap(i => i.text.trim())
  const text = lines.join(' ')

  return { text, lines }
}

/** Converts Transcription to WEBVTT format */
export function transcriptionToVTT(captions: Recording.ITranscription) {
  let vttContent = 'WEBVTT\n\n'

  function formatTime(time: number): string {
    const date = new Date(time * 1000)
    return date.toISOString().substring(11, 23)
  }

  for (let i = 0; i < captions.length; i++) {
    const segment = captions[i]

    for (let j = 0; j < segment.sentences.length; j++) {
      const sentence = segment.sentences[j]
      const startTime = formatTime(sentence.start)
      const endTime = formatTime(sentence.end)

      vttContent += `${i + 1}.${j + 1}\n${startTime} --> ${endTime}\n`
      vttContent += `${sentence.text}\n\n`
    }
  }

  return vttContent
}

/** Extends Recording model with utilities */
export function useRecordingUtils<T extends Partial<Recording> & { id: string, domain?: string }>(source: T) {
  interface SharingData {
    title: string
    url: string
  }

  /** Natively share recording */
  function share({
    title = source.subject || 'Listen to my voice message',
    url = getLink()
  } = {}) {
    track('Share recording', { url, provider: 'OS' })
    return navigator.share({ title, url })
  }

  /** Share recording to a provider */
  function socialShare(
    provider: string,
    builder: (data: SharingData) => string,
    { title = source.subject || 'Listen to my voice message' } = {}
  ) {
    const data: SharingData = {
      title,
      url: getLink()
    }

    // Dev: replace localhost url
    if (import.meta.env.DEV)
      data.url = data.url.replace('localhost:5173', 'vocal-dev.vercel.app')

    const url = encodeURI(builder(data))

    track('Share recording', { url, provider })
    return window.open(url, '_blank')
  }

  function getCaptions(length = Number.POSITIVE_INFINITY) {
    const content = parseTranscriptionContent(source.transcription)
      .text
      .slice(0, length - 3)
      .trim()

    return content ? `${content}...` : ''
  }

  /** Parse title from subject or captions */
  function getTitle({
    maxLength = 50,
    trailingDots = true
  } = {}) {
    const { subject = '', transcription } = source
    const { text } = parseTranscriptionContent(transcription)

    let content = subject || text.slice(0, maxLength)

    // Pad trailing dots
    if (trailingDots && !subject && text.length > maxLength)
      content += '...'

    return content.trim()
  }

  /** Returns /listen page link */
  function getLink() {
    return parseURL(source.domain || import.meta.env.VITE_APP_WEBAPP_ORIGIN, 'listen', source.id).href
  }

  /** Returns asset url from cloudstorage */
  function getAssetUrl(extension: 'mp3' | 'png') {
    return `https://storage.googleapis.com/vocal_messages/${source.id}.${extension}` as const
  }

  /** Copies page link to clipboard */
  function copyLink() {
    const url = getLink()

    track('Share recording', { url, provider: 'URL' })
    return copyText(url)
  }

  /** Copies image to clipboard */
  function copyImage() {
    const url = getLink()
    const title = getTitle({ maxLength: 200 })
    const imageLink = source.imageUrl || getAssetUrl('png')

    const content = `
      <a href="${url}">
        <span style="margin-bottom: 6px; font-size: 14px;">${title}</span>
        <img src="${imageLink}" width="350" style="margin: 0px; box-sizing: border-box; padding: 0px; max-width: 350px;">
      </a>
    `

    track('Share recording', { url, provider: 'Image' })
    return copyRichHTML(content)
  }

  /** Downloads the audio file */
  function download(filename = 'recording.mp3') {
    return downloadFile(source.audioUrl || getAssetUrl('mp3'), filename)
  }

  return {
    ...source,

    getTitle,
    getCaptions,

    getLink,
    getAssetUrl,
    copyLink,
    copyImage,
    download,

    share,
    socialShare
  }
}

export class FileDate extends Date {
  getRelativeTime(unit: Intl.RelativeTimeFormatUnit = 'day') {
    const DAY_MILLISECONDS = 1000 * 60 * 60 * 24

    const rtf = new Intl.RelativeTimeFormat('en', {
      numeric: 'auto'
    })

    const daysDifference = Math.round(
      (this.getTime() - new Date().getTime()) / DAY_MILLISECONDS
    )

    return rtf.format(daysDifference, unit)
  }

  /** Safely parses given date, otherwise returns current date */
  static safeParse(value?: string | Date) {
    const fallback = new FileDate()

    try {
      if (!value)
        return fallback
      return new FileDate(value)
    }
    catch (error) {
      console.error('FileDate: Failed to parse date', { value, error })
    }

    return fallback
  }
}
