<script lang="ts" setup>
import { ReactiveStripeElement, useState } from '../../logic'
import VerifyCard from './StripeInput.VerifyCard.vue'

const stripe = new ReactiveStripeElement()
const state = stripe.state

const payment = useState()
const checkout = () => payment.subscribe(stripe)
</script>

<template>
  <VerifyCard v-if="state.checkoutUrl" :cause="state.error" :url="state.checkoutUrl" />

  <div v-else class="grid gap-4">
    <slot />

    <stripe.InputCard :class="$style.input_wrapper" />

    <!-- Error message -->
    <span v-if="state.error" role="alert" class="font-medium text-label-4 text-error-500">
      <i-mdi-alert-circle class="inline" />
      {{ state.error }}
    </span>

    <CTAButton :disabled="!state.isValid" @resolve-click="checkout">
      Upgrade now
    </CTAButton>
  </div>
</template>

<style module lang="sass">
.input_wrapper
  @apply px-4 h-12 flex-center w-full rounded-10px relative overflow-hidden
  @apply bg-neutral-100 border-2 border-solid border-transparent

  &.focus
    @apply bg-shade-light border-primary-500

  &.invalid
    @apply border-error-500

  // Child Frame
  & > div
    @apply w-full opacity-0

    transition: all 300ms ease
    transform: translateY(20px)

  &[data-loaded="true"] > div
    opacity: 1
    transform: translateY(0px)

  // Loader
  &[data-loaded="false"]::before
    @apply h-18px w-18px content-[''] absolute top-2/4 left-2/4
    @apply rounded-full border-3 border-solid border-neutral-500 border-b-transparent

    transform: translate(-50%, -50%)
    animation: spin 1s linear infinite

    @keyframes spin
      to
        transform: translate(-50%, -50%) rotate(360deg)
</style>
