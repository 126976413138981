import { Logger } from '../../diagnostics/logger'

export class Obfuscator {
  static #key = 79

  static process(value: any, key = this.#key, encrypt = true): string {
    let result = ''

    try {
      result = encrypt
        ? [...JSON.stringify(value)]
            .map(x => String.fromCharCode(x.charCodeAt(0) + key))
            .join('')

        : JSON.parse(
            [...value]
              .map(x => String.fromCharCode(x.charCodeAt(0) - key))
              .join('')
          )
    }
    catch (error) {
      if (!String(error).includes('is not iterable'))
        Logger.warn(`⚙️ ${Obfuscator.name}: Could not parse`, { value, error })
    }

    return result
  }

  static encrypt(value: any) {
    return this.process(value, undefined, true)
  }

  static decrypt(value: any) {
    return this.process(value, undefined, false)
  }
}
