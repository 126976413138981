import { StreamUtil } from 'vocal-recorder'

export namespace MicManager {
  export enum State {
    GRANTED = 'granted',
    DENIED = 'denied',
    PROMPT = 'prompt',
    UNAVAILABLE = 'unavailable'
  }

  export const use = createSharedComposable(() => {
    const state = useLocalStorage<`${State}`>('MIC_PERMISSION_STATE', State.PROMPT)
    const devices = ref<MediaDeviceInfo[]>([])

    // Events
    const events = {
      deviceChanged: createEventHook<MediaDeviceInfo[]>(),
      permissionChanged: createEventHook<`${State}`>()
    }

    async function updateDeviceList() {
      try {
        const list = await navigator.mediaDevices.enumerateDevices()
        const inputs = list.filter(i => i.kind === 'audioinput')

        devices.value = inputs
        events.deviceChanged.trigger(inputs)
      }

      catch (error) {
        ErrorService.log(error, { title: 'Error occurred while updating audio device list' })
      }
    }

    async function request() {
      try {
        const stream = await StreamUtil.get()

        if (stream.active)
          state.value = State.GRANTED

        StreamUtil.dispose(stream)
        await updateDeviceList()
      }
      catch (error) {
        ErrorService.logMessage('Error occurred while requesting mic permission', {
          extra: { error }
        })

        state.value = State.DENIED
      }

      events.permissionChanged.trigger(state.value)
      console.info('Mic permission changed', state.value)

      return state.value === 'granted'
    }

    return toReactive({ state, devices, events, request })
  })
}
